import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { Button, message } from 'antd';
import { FireOutlined } from '@ant-design/icons';
import { FaRegBuilding } from 'react-icons/fa';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';

import SEO from '../blocks/layout/seo';
import AppContainer from '../blocks/layout';
import { pricingPlans } from '../data/plans';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { Firebase } from '../config/firebase';
import { getPlanId } from '../config/custom/functions';
import Checkout from '../blocks/codes/checkout';
import FAQs from '../blocks/codes/faq';

import '../styles/plan.scss';

const PricingPlans = () => {
      const [planType, setPlanType] = useState('yearly');
      const [loading, setLoading] = useState(false);
      const [userPlan, setUserPlan] = useState('Free');
      const [custID, setCustID] = useState('');
      const state = useContext(GlobalStateContext);

      const { user } = state;
      const [uid, setUid] = useState('');

      useEffect(() => {
            setLoading(true);
            async function fetchUserSubscription(userId) {
                  const subRes = await axios.get(
                        `${process.env.GATSBY_BASE_URL}/subscriptions/${userId}`
                  );
                  setUid(user.uid);
                  const { message } = subRes.data;
                  if (message === 'No subscription') setUserPlan('Free');

                  if (
                        user.status[0] === 'pro' &&
                        user.customer_stripe !== null
                  ) {
                        const { planNickName, customer } = user.customer_stripe;
                        setCustID(customer);
                        if (planNickName !== undefined) {
                              setUserPlan(planNickName);
                        } else {
                              setUserPlan('pro');
                        }
                  } else {
                        setUserPlan('Free');
                  }
                  setLoading(false);
                  if (user.notFound) {
                        navigate('/dashboard/setup');
                  }
            }
            Firebase.auth().onAuthStateChanged((firebaseUser) => {
                  if (firebaseUser && user !== null && user !== undefined) {
                        fetchUserSubscription(user.uid);
                  }
            });
      }, [user]);

      const makeUserPortalLink = () => {
            message.info('Loading...');
            const data = JSON.stringify({ customer_id: custID });

            const config = {
                  method: 'post',
                  url: `${process.env.GATSBY_BASE_URL}/customer_portal_link`,
                  headers: {
                        'Content-Type': 'application/json',
                  },
                  data,
            };

            axios(config)
                  .then(function (response) {
                        const { url } = response.data;
                        navigate(url);
                  })
                  .catch(function (error) {
                        console.log(error);
                  });
      };

      return (
            <>
                  <SEO title="Pricing Plan — NoCodeAPI" />
                  <AppContainer activeNav="plan">
                        {loading ? (
                              'Loading...'
                        ) : (
                              <div className="page-content-header">
                                    <h2>Plan & Pricing</h2>
                                    <p>
                                          Your current plan and options to
                                          upgrade for high plan.
                                    </p>
                                    <>
                                          <div className="annually-note">
                                                <b
                                                      style={{
                                                            backgroundColor:
                                                                  '#ffcd58',
                                                            color: '#000',
                                                            padding: '0px 3px',
                                                            borderRadius: 3,
                                                      }}
                                                >
                                                      Note:
                                                </b>{' '}
                                                Save 2-months on annually
                                                subscription
                                          </div>
                                          <div className="list-of-all-plans">
                                                {pricingPlans.map(
                                                      (planObj, key) => (
                                                            <div
                                                                  key={key}
                                                                  className={
                                                                        planObj.plan ===
                                                                        'Professional'
                                                                              ? 'plan-card popular-plan'
                                                                              : 'plan-card'
                                                                  }
                                                            >
                                                                  {planObj.plan ===
                                                                        'Professional' && (
                                                                        <div className="popular-tag">
                                                                              {userPlan ===
                                                                              planObj.plan ? (
                                                                                    <span
                                                                                          style={{
                                                                                                display: 'flex',
                                                                                                alignItems:
                                                                                                      'center',
                                                                                          }}
                                                                                    >
                                                                                          <BsFillBookmarkCheckFill
                                                                                                style={{
                                                                                                      marginRight: 4,
                                                                                                }}
                                                                                          />{' '}
                                                                                          Active
                                                                                          plan
                                                                                    </span>
                                                                              ) : (
                                                                                    <>
                                                                                          <FireOutlined />{' '}
                                                                                          Most
                                                                                          Popular
                                                                                    </>
                                                                              )}
                                                                        </div>
                                                                  )}
                                                                  <div className="name">
                                                                        {
                                                                              planObj.plan
                                                                        }
                                                                  </div>
                                                                  <div className="price">
                                                                        $
                                                                        {
                                                                              planObj.monthlyPrice
                                                                        }
                                                                        <span>
                                                                              /month
                                                                        </span>
                                                                  </div>
                                                                  <div
                                                                        className="description"
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: planObj.description,
                                                                        }}
                                                                  />
                                                                  {userPlan !==
                                                                        planObj.plan &&
                                                                        userPlan !==
                                                                              'Free' && (
                                                                              <Button
                                                                                    type="primary"
                                                                                    block
                                                                                    onClick={
                                                                                          makeUserPortalLink
                                                                                    }
                                                                                    style={{
                                                                                          fontSize: 13,
                                                                                          fontWeight: 400,
                                                                                    }}
                                                                              >
                                                                                    Upgrade
                                                                                    to{' '}
                                                                                    {
                                                                                          planObj.plan
                                                                                    }{' '}
                                                                                    plan
                                                                              </Button>
                                                                        )}
                                                                  {userPlan ===
                                                                        'Free' && (
                                                                        <>
                                                                              <Checkout
                                                                                    userId={`${uid}-${planObj.apiCalls}-${planObj.plan}`}
                                                                                    btnText={`Pay with Debit or Credit Card`}
                                                                                    plan={getPlanId(
                                                                                          planObj.planType,
                                                                                          'monthly'
                                                                                    )}
                                                                                    planType="monthly"
                                                                                    monthlyPrice={
                                                                                          planObj.monthlyPrice
                                                                                    }
                                                                                    planName={
                                                                                          planObj.plan
                                                                                    }
                                                                              />
                                                                              <br />
                                                                              <br />
                                                                              <Checkout
                                                                                    userId={`${uid}-${planObj.apiCalls}-${planObj.plan}`}
                                                                                    btnText={`Pay with Debit or Credit Card`}
                                                                                    plan={getPlanId(
                                                                                          planObj.planType,
                                                                                          'yearly'
                                                                                    )}
                                                                                    planType="yearly"
                                                                                    monthlyPrice={
                                                                                          planObj.monthlyPrice
                                                                                    }
                                                                                    planName={
                                                                                          planObj.plan
                                                                                    }
                                                                              />
                                                                        </>
                                                                  )}
                                                            </div>
                                                      )
                                                )}
                                          </div>
                                          <div className="request-quote">
                                                <p>
                                                      <FaRegBuilding />
                                                      Need unlimited API
                                                      requests? We also offer
                                                      custom{' '}
                                                      <b>
                                                            <a
                                                                  href="https://nocodeapi.com/contact"
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                            >
                                                                  Enterprise
                                                                  plan
                                                            </a>
                                                      </b>{' '}
                                                      for unlimted API requests,
                                                      Dedicated server and more
                                                      --{' '}
                                                      <b>
                                                            <a
                                                                  href="https://nocodeapi.com/contact"
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                            >
                                                                  Request Quote
                                                            </a>
                                                      </b>
                                                </p>
                                          </div>
                                          <FAQs />
                                    </>
                              </div>
                        )}
                  </AppContainer>
            </>
      );
};

export default PricingPlans;
