import React from 'react';
import { Button, message } from 'antd';
import { navigate } from 'gatsby';
import axios from 'axios';

const Checkout = ({ plan, userId, planType, monthlyPrice }) => {

      const redirectToCheckout = () => {
            message.info('Redirecting to payment page...');
            let rewardfulReferenceId = ''
            window.rewardful('ready', function() {
                        rewardfulReferenceId =  window.Rewardful && window.Rewardful.referral || ('checkout_'+(new Date).getTime());
            });
            

            const data = JSON.stringify({
                  clientReferenceId: userId,
                  price: plan,
                  rewardfulReferenceId
            });

            const config = {
                  method: 'post',
                  url: `${process.env.GATSBY_BASE_URL}/create_checkout_link`,
                  headers: {
                        'Content-Type': 'application/json',
                  },
                  data,
            };

            axios(config)
                  .then(function (response) {
                        const { url } = response.data;
                        window.open(url);
                  })
                  .catch(function (error) {
                        console.log(error);
                  });
      };

      return (
            <>
                  <Button
                        type={planType === 'monthly' && 'primary'}
                        block
                        onClick={() => redirectToCheckout()}
                        style={{
                              fontSize: 13,
                              fontWeight: 400,
                        }}
                  >
                        {planType === 'monthly'
                              ? `Pay Monthly — $${monthlyPrice}`
                              : `Pay Annually — $${
                                      monthlyPrice * 12 - monthlyPrice * 2
                                }`}
                  </Button>
            </>
      );
};

export default Checkout;
