export const pricingPlans = [
      {
            plan: 'Personal',
            description:
                  'Standard Kit — includes core features and <span>50,000 monthly API requests</span>.',
            monthlyPrice: 12,
            save: 38,
            yearlyPrice: 6,
            planType: 'basic',
            apiCalls: 50000,
      },
      {
            plan: 'Professional',
            description:
                  'Startup Plan — higher volume to build your MVPs. up to <span>150,000 monthly API requests</span>.',
            monthlyPrice: 29,
            save: 98,
            yearlyPrice: 14,
            planType: 'professional',
            apiCalls: 150000,
      },
      {
            plan: 'Business',
            description:
                  'Smart Option — complete suite of advanced features and <span>400,000 monthly API requests</span>.',
            monthlyPrice: 49,
            save: 138,
            yearlyPrice: 24,
            planType: 'business',
            apiCalls: 400000,
      },
];
