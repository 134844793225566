export const faq = [
      {
            q: 'How is this different from Zapier?',
            a: 'Zapier connects multiple applications together. While NoCodeAPI converts your <b>Applications data</b> to <b>Simple</b> & <b>Secure</b> APIs. You can use it with zapier to connect with other apps. Or Integromat to run time triggered flows. Or postman to list your data in JSON.',
      },
      {
            q: 'Do I need to know to Code?',
            a: 'No! You need to be able to find your API key of your app. We have simplest documentation / video tutorials to make your app building experience both fun and easy.',
      },
      {
            q: 'What are the benifits of using this tool?',
            a: 'Our Micro documentation saves you hours of development time. We provide a <b>Cache layer</b> on top each call, that lets you <b>bypass limits</b> (e.g on Weather API you save a ton on your limit). <b>Secure your Keys</b> in our encrypted lockers, <b>track your history logs</b>, <b>bypass paginations</b> and much much more.',
      },
      {
            q: 'Do I have to pay to try it?',
            a: 'No! We give <b>300 free API requests per month</b>. You can play around to see what premium plan suits you.',
      },
      {
            q: 'Do I have pay extra for future integrations?',
            a: "No! You buy one subscription and we open our whole awesome <a href='https://nocodeapi.com/marketplace' target='_blank'>library of APIs</a> to you for playing. We have one place to count all your API calls. Once your calls exceed the limit, we believe you are producing enough cash to buy our next plan.",
      },
      {
            q: 'Can I use nocodeapi with other nocode tools?',
            a: 'Yes. nocodeapi works with <b>Bubble</b>, <b>Webflow</b>, <b>Adalo</b>, <b>Integromat</b>, <b>glideapps</b>, <b>zapier</b>, <b>parabola</b> and nearly everything that uses API.',
      },
      {
            q: 'What happens, after I consume 100 % API Requests?',
            a: 'Free users receive a <b>429 Error Code</b>. While Paid users, find everything working smooth. And we send you a reminder to upgrade your package email. You upgrade as soon as possible to make sure you do not interrupt your customers.',
      },
      {
            q: 'How do I upgrade my Subscription Plan?',
            a: 'Login to your dashboard and go to billing page to upgrade your plan.',
      },
      {
            q: 'I don’t find my integration into marketplace.',
            a: "Send us your request. And we will add your request on our roadmap. Keep an eye on our roadmap and <a href='https://nocodeapi.com/roadmap' target='_blank'>request for new integration</a>",
      },
      {
            q: 'Do you have public roadmap?',
            a: "Yes, you can track all the progress on <b>In progress</b>, <b>planned</b> & <b>future tasks</b> on <a href='https://nocodeapi.com/roadmap' target='_blank'>https://nocodeapi.com/roadmap</a>",
      },
      {
            q: 'What payment methods do we support?',
            a: 'Stripe (Supported cards: <b>Visa</b>, <b>Mastercard</b>, <b>American Express</b>) and Paypal (coming soon).',
      },
      {
            q: 'Can I get a refund?',
            a: 'Yes. If you contact us in <b>under 10 days</b> of your purchase. It is Full Refund without any questions.',
      },
      {
            q: 'Have more questions?',
            a: 'Nice ! Throw us your cold email at hello@nocodeapi.com.',
      },
];
