import React from 'react';

import { faq } from '../../data/faq';

const FAQs = () => {
      return (
            <div>
                  <h2 style={{ margin: '4rem 0px', fontWeight: 600 }}>
                        Frequently asked questions
                  </h2>
                  <div className="faq-list">
                        {faq.map((obj, key) => (
                              <div className="qa" key={key}>
                                    <div className="question">{obj.q}</div>
                                    <div
                                          className="answer"
                                          dangerouslySetInnerHTML={{
                                                __html: obj.a,
                                          }}
                                    />
                              </div>
                        ))}
                  </div>
            </div>
      );
};

export default FAQs;
